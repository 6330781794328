<template>
    <CRow>
        <CCol sm="12">
            <TopHeadline :headerTitle="$t('pages.shares.transactionInfomation')" :backTitle="$t('common.back')" :onGoBack="onBackPage">
                <template v-slot:headerRight v-if="stockTransactionDetail != null">
                    <div v-if="isAdmin && stockTransactionDetail.status == 0">
                        <button type="button" name="approve-stock" class="btn btn-success" @click="onApproveOrder">
                            {{ $t('pages.warehouse.approve') }}
                        </button>
                        <button type="button" name="reject-stock" class="btn btn-danger" @click="onRejectedOrder">
                            {{ $t('common.rejected') }}
                        </button>
                    </div>
                </template>
            </TopHeadline>

            <Accordion :headerTitle="$t('pages.shares.transactionInfomation')" v-if="stockTransactionDetail != null">
                <template v-slot:body>
                    <CRow>
                        <CCol sm="12">
                            <CForm>
                                <CInput :label="$t('pages.shares.code')" :value="stockTransactionDetail.transactionId" horizontal readonly></CInput>
                                <CInput :label="$t('common.name')" :value="stockTransactionDetail.userName" horizontal readonly></CInput>
                                <CInput :label="$t('common.price')" :value="$func.formatNumber(stockTransactionDetail.price)" horizontal readonly></CInput>
                                <CInput :label="$t('common.quantity')" :value="$func.formatNumber(stockTransactionDetail.quantity)" horizontal readonly></CInput>
                                <CInput :label="$t('common.amount')" :value="$func.formatNumber(stockTransactionDetail.amount)" horizontal readonly></CInput>
                                <CInput :label="$t('common.createdDate')" :value="formatDate(stockTransactionDetail.createdDate)" horizontal readonly></CInput>
                                <CInput :label="$t('common.status')" :value="showStatus(stockTransactionDetail.status)" horizontal readonly></CInput>
                            </CForm>
                        </CCol>
                    </CRow>
                </template>
            </Accordion>
           
            <div v-if="stockTransactionDetail != null">
                <Accordion :headerTitle="$t('pages.deposit.recipientOfaccountInformation')" expand>
                    <template v-slot:body>
                        <div v-if="bankAccountList != null">
                            <CRow v-for="(item) in bankAccountList" v-bind:key="item.id">
                                <CCol sm="2">
                                    <b> {{$t('pages.bank.account')}}: {{ item.bankCode }}</b>
                                </CCol>
                                <CCol sm="6">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <label><b>{{ $t('pages.bank.bankName') }}</b></label>
                                        </div>
                                        <div class="col-md-6">
                                            <p><b>{{ item.bankName }}</b></p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <label>{{ $t('pages.bank.accountName') }}</label>
                                        </div>
                                        <div class="col-md-6">
                                            <p>{{  item.accountName }}</p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <label>{{ $t('pages.bank.accountNo') }}</label>
                                        </div>
                                        <div class="col-md-6">
                                            <p>{{  item.accountNo }}</p>
                                        </div>
                                    </div>
                                </CCol>
                            </CRow>
                        </div>
                    </template>
                </Accordion>
            </div>                 
            <Confirmation ref="confirmation"></Confirmation>
        </CCol>
    </CRow>  
</template>

<script>
    import { mapGetters, mapState, mapActions } from 'vuex'
    import i18n from '@/plugins/i18n'   
    import Accordion from '@/components/Accordion.vue'
    import Confirmation from '@/components/Confirmation.vue'
    import TopHeadline from '@/components/TopHeadline.vue'
    import CommonStatus from '@/helpers/CommonStatus'
    
    export default {
        name: 'Detail',
		data() {            
			return {                
                horizontal: { label: 'col-3', input: 'col-9' },
                isAdmin: false,          
            };
		},
        components: {          
            Accordion, Confirmation, TopHeadline
        },
        computed: {
            ...mapGetters('auth', ['user']),                      
            ...mapState('stockTrading', ['stockTransactionDetail', 'bankAccountList'])
        },
        methods: {            
            ...mapActions('stockTrading', ['getStockTransactionById', 'changeTradingStockStatus']),

            formatDate(date) {
                if (date === undefined)
                    return '';
                return this.$moment(date, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss');
            },
            showStatus(iStatus) {

                if (CommonStatus.Completed === iStatus)
                    return i18n.t('common.completed');
                else if (CommonStatus.Rejected === iStatus)
                    return i18n.t('common.rejected');
                else if (CommonStatus.Canceled === iStatus)
                    return i18n.t('common.canceled');
                else
                    return i18n.t('common.pending');

            },
            onBackPage() {
                if (this.isAdmin)
                    this.$router.push('/shares/listadmin');
                else
                    this.$router.push('/shares/list');
            },           

            async onApproveOrder() {
                this.$refs.confirmation.show(i18n.t('common.confirmMakeChange'), async () => {
                    var retVal = await this.changeTradingStockStatus({ id: this.stockTransactionDetail.transactionId, newStatus: CommonStatus.Completed });

                    if (retVal == true) {
                        this.$store.dispatch('toast/displayNotification', { text: i18n.t('common.successfully') });
                        this.onBackPage();
                    }
                    else {
                        this.$store.dispatch('alert/error', i18n.t('common.unsuccessfull'), { root: true });
                    }
                });
            },
            async onRejectedOrder() {
                this.$refs.confirmation.show(i18n.t('pages.shares.confirmRejectCommand'), async () => {
                    var retVal = await this.changeTradingStockStatus({ id: this.stockTransactionDetail.transactionId, newStatus: CommonStatus.Rejected });
                   
                    if (retVal == true) {
                        this.$store.dispatch('toast/displayNotification', { text: i18n.t('common.successfully') });
                        this.onBackPage();
                    }
                    else {
                        this.$store.dispatch('alert/error', i18n.t('common.unsuccessfull'), { root: true });
                    }
                });
            },
		},
        mounted() {
            if (this.$store.state.auth.user.role === 'admin') {
                this.isAdmin = true;                
            }
            
            this.getStockTransactionById({ transactionId: this.$route.params.id });
                                              
        }
	}
</script>

<style scoped>
    .btn-primary {
        background-color: #3c8dbc;
        border-color: #367fa9;
    }

        .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle {
            color: #fff;
            background-color: #0062cc;
            border-color: #005cbf;
        }

    .btn-danger:not(:disabled):not(.disabled).active, .btn-danger:not(:disabled):not(.disabled):active, .show > .btn-danger.dropdown-toggle {
        color: #fff;
        background-color: #bd2130;
        border-color: #b21f2d;
    }

    a:hover {
        color: #0056b3;
        text-decoration: none;
    }

    a {
        color: #0056b3;
    }

    .content-header h1 {
        font-size: 1.8rem;
        margin: 0;
    }

    .content-header > h1 {
        margin-bottom: 10px;
    }

    .float-left {
        float: left !important;
    }

    .float-right {
        float: right !important;
    }

    .clearfix::after {
        display: block;
        clear: both;
        content: "";
    }

    .content-header > h1 > small {
        color: #0076bb;
        font-weight: 400;
        margin-left: 6px;
        font-size: 65%;
    }

        .content-header > h1 > small i {
            font-size: .8em;
            padding-right: 2px;
        }

    header {
        background: #3c4b64
    }

    h5 {
        font-size: 1.09375rem;
    }

        h5 a {
            color: #fff;
        }

    .mb-0 {
        margin-bottom: 0 !important;
    }

    .float-right button {
        margin-left: 0.2rem;
    }
</style>